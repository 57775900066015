import React, { forwardRef, useContext } from "react";
import styled from "styled-components";
import FlexContainer from "../common/_flexContainer";
import { workspaceAvatarMap } from "../common/_workspaceAvatarMap";
import Text from "../common/_text";
import Tooltip from "../common/_tooltip";
import { AuthContext } from "../../context/AuthContext";
import {
  StyledButtonPrimary,
  StyledButtonsecondary,
} from "../common/_buttonNewOne";
import { useHistory } from "react-router-dom";
import { CreateEditWorkspaceModalContext } from "../../context/CreateEditWorkspaceModalContext";

const FlexContainerForDropdown = styled(FlexContainer)`
  background: #2f2f2f;
  width: 18.055rem;
  max-height: 18.333rem;
  padding: 0.555rem;
  border: 0.069rem solid #5e5e5e;
  border-radius: 0.833rem;
  box-shadow: 0.277rem 0.277rem 1.111rem 0.277rem #0000001c;
  z-index: 1;
  font-family: "Articulat CF Medium";
  font-weight: 500;
  font-size: 1.111rem;
  line-height: 1.3;
  color: #f6f6f6;
  text-align: start;
`;
const FlexContainerForDropdownItem = styled(FlexContainer)`
  width: 100%;
  padding: 0.555rem;
  justify-content: flex-start;
  gap: 0.833rem;
  border-radius: 0.555rem;
  cursor: pointer;
  &:hover {
    background: #4b4b4b;
  }
`;
const AvatarIcon = styled.div`
  width: 2.222rem;
  height: 2.222rem;
`;
const PrimaryButtonStyled = styled(StyledButtonPrimary)`
  margin: 0.555rem;
  width: 100%;
  height: 2.777rem;
  font-weight: bold;
  font-size: 0.972rem;
  border-radius: 0.555rem;
`;
const SecondaryButtonStyled = styled(StyledButtonsecondary)`
  margin: 0.555rem;
  width: 100%;
  height: 2.777rem;
  font-weight: bold;
  font-size: 0.972rem;
  border-radius: 0.555rem;
`;

const WorkspaceDropdownContent = forwardRef(
  ({ workspaceList, ...props }, ref) => {
    const { setSelectedWorkspaceData, userRole } = useContext(AuthContext);
    const { setIsWorkspaceModalInEditMode, setIsCreateEditWorkspaceModalOpen } =
      useContext(CreateEditWorkspaceModalContext);
    const history = useHistory();

    return (
      <FlexContainerForDropdown ref={ref} {...props}>
        {workspaceList.map((workspace) => (
          <FlexContainerForDropdownItem
            key={workspace.id}
            onClick={() => {
              setSelectedWorkspaceData(workspace);
              history.push("/connectorsPlayground");
            }}
          >
            <AvatarIcon as={workspaceAvatarMap[workspace.iconFile]} />
            <Tooltip title={workspace.workspaceName}>
              <Text
                $width="calc(100% - 2.222rem - 0.833rem)"
                $fontWeight="bold"
                $fontSize="1.111rem"
                $noWrap
              >
                {workspace.workspaceName}
              </Text>
            </Tooltip>
          </FlexContainerForDropdownItem>
        ))}
        {workspaceList.length ? (
          <SecondaryButtonStyled onClick={() => history.push("/onBoarding")}>
            View All Workspaces
          </SecondaryButtonStyled>
        ) : (
          <>
            <Text $margin="0.555rem" $fontSize="0.972rem" $textAlign="center">
              {userRole === "ADMIN"
                ? "You currently have no other workspaces added. Click on the button below to add a new workspace!"
                : "You currently have no other workspaces assigned"}
            </Text>
            {/* TODO-NISHANT: Add ask sachin on what needs to be done */}
            {userRole === "ADMIN" && (
              <PrimaryButtonStyled
                onClick={() => {
                  setIsWorkspaceModalInEditMode(false);
                  setIsCreateEditWorkspaceModalOpen(true);
                }}
              >
                Add New Workspace
              </PrimaryButtonStyled>
            )}
          </>
        )}
      </FlexContainerForDropdown>
    );
  },
);

export default WorkspaceDropdownContent;
