// External imports
import React, { useContext } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import "../../scss/PreBuiltConnectUpdated.scss";
import ConnectorIcon from "../../assets/images/Connectors.svg";
import { SnackbarContext } from "../../layouts/Context/snackBarContext";

const truncateContent = (content, maxLength = 50) => {
  return content.length > maxLength
    ? `${content.slice(0, maxLength)}...`
    : content;
};

// Styled components
const Container = styled.div`
  display: flex;
  background-color: #2f2f2f;
  border-radius: 0.84rem;
  color: #fafafa;
  font-family: "Articulat CF Medium";
`;

const LeftContent = styled.div`
  display: flex;
  width: 10%;
  align-items: center;
  justify-content: center;
`;

const RightContent = styled.div`
  width: 90%;
  padding-top: 1.1rem;
`;

const Line = styled.div`
  width: 0.3rem;
  height: 9.9rem;
  background-color: ${(props) => getCategoryColor(props.connectorType)};
  border-radius: 0.35rem;
`;

const ConnectorNameArea = styled.div`
  display: flex;
  align-items: center;
`;

const StyledIcon = styled.img`
  max-height: 1.3rem;
  max-width: 1.3rem;
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const LogoArea = styled.div`
  height: 2.25rem;
  width: 2.25rem;
  border-radius: 0.35rem;
  background-color: #4b4b4b;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CategoryDiv = styled.div`
  display: flex;
  margin-top: 1.39rem;
`;

const CategoryArea = styled.div`
  display: flex;
  flex-direction: column;
`;

const CategoryTitle = styled.div`
  font-size: 0.84rem;
  color: #777777;
`;

const CategoryContent = styled.div`
  font-size: 0.84rem;
  color: #47ccd6;
`;

const Title = styled.div`
  font-size: 1.115rem;
  font-family: "Articulat CF Medium";
  font-weight: bold;
  padding-left: 0.6rem;
`;

const Description = styled.div`
  font-family: "Articulat CF Medium";
  font-size: 0.84rem;
  margin-top: 1.39rem;
  margin-bottom: 0.84rem;
  height: 2.1rem;
  line-height: 1rem;
`;

const getCategoryColor = (category) => {
  switch (category) {
    case "Stream Connector":
      return "#6DC89B";
    case "Store Connector":
      return "#B5E3CE";
    case "Application":
      return "#EB6F95";
    case "Telematics Device":
      return "#F7C0D5";
    default:
      return "#EB6F95";
  }
};

/**
 * PreBuiltCard component renders a card displaying information about a connector.
 *
 * @param {string} props.title - The title of the connector.
 * @param {string} props.category - The category of the connector.
 * @param {string} props.type - The type of the connector.
 * @param {string} props.description - The description of the connector.
 * @param {} icon - The link of icon of that particular type connector.
 *
 * @returns {JSX.Element} The rendered PreBuiltCard component.
 */

const PreBuiltCard = ({
  title = "NA",
  category = "NA",
  type = "NA",
  description = "NA",
  icon,
  id,
  handlePreConnectorClick,
  isClickable,
}) => {
  const { showMessage } = useContext(SnackbarContext);

  return (
    <Container
      onClick={() => {
        if (isClickable) {
          handlePreConnectorClick(
            title ? title : title,
            type ? type : "INPUT",
            id,
            category,
            description,
          );
        } else {
          showMessage("You don't have permission to perform this operation");
        }
      }}
    >
      <LeftContent>
        <Line connectorType={category} />
      </LeftContent>
      <RightContent>
        <ConnectorNameArea>
          <LogoArea>
            <StyledIcon src={icon || ConnectorIcon} alt="Connector Icon" />
          </LogoArea>
          <Title>{title}</Title>
        </ConnectorNameArea>
        <CategoryDiv>
          <CategoryArea style={{ width: "60%" }}>
            <CategoryTitle>Category</CategoryTitle>
            <CategoryContent style={{ color: getCategoryColor(category) }}>
              {category}
            </CategoryContent>
          </CategoryArea>
          <CategoryArea style={{ width: "40%" }}>
            <CategoryTitle>Type</CategoryTitle>
            <CategoryContent style={{ color: getCategoryColor(category) }}>
              {type}
            </CategoryContent>
          </CategoryArea>
        </CategoryDiv>
        <Description>{truncateContent(description)}</Description>
      </RightContent>
    </Container>
  );
};

// Prop types
PreBuiltCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
};

export default PreBuiltCard;
