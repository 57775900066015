import OnboardingMainPage from "../pages/OnboardingPages/OnBoardingMainPage";
import SupportPage from "../pages/LandingPages/SupportPage";
import WorkSpaceConnectorsMainPage from "../pages/WorkSpaceConnectors/WorkSpaceConnectorsMainPage";
import OauthAuthenticatePage from "../components/editorComponents/OAuthLoadingPage.jsx";
import GeoFenceMain from "../components/geoFenceComponents/geoFenceMain.jsx";
import ApplicationPage from "../pages/ApplicationsPage/ApplicationPage.jsx";
import NewApplicationPage from "../pages/ApplicationsPage/NewApplicationPage.jsx";
import BuildLogsAndConfiguration from "../components/buildApplicationTerminal/BuildLogsAndConfigurationPage.jsx";
import ConnectorInfoConfiguration from "../components/connectorInformation/ConnectorInfoConfigurationPage.jsx";
import VSCodeEditor from "../pages/ApplicationsPage/VSCodeEditor.jsx";
import RepoInformation from "../pages/ApplicationsPage/RepoInformation.jsx";
import Settings from "../pages/WorkspaceSettings/index.jsx";
import { SignIn } from "../pages/NewSignInAndSignUpPages/SignIn.jsx";
import { SignUp } from "../pages/NewSignInAndSignUpPages/signUp.jsx";
import { OtpVerification } from "../pages/NewSignInAndSignUpPages/OtpVerification.jsx";
import { ForgotPassword } from "../pages/NewSignInAndSignUpPages/ForgotPassword.jsx";
import { CreateNewAccount } from "../pages/NewSignInAndSignUpPages/CreateNewAccount.jsx";
import { ResetPasswordNew } from "../pages/NewSignInAndSignUpPages/ResetPassword.jsx";
import ConnectorsConfigurationPage from "../pages/WorkSpaceConnectors/ConnectorsConfigurationPage.jsx";
import PipelinesPageNew from "../pages/PipelinesPageNew/PipelinesPageNew.jsx";

/**
 *  @type {Array}
 *  @description This Array contains all the Public Routes.
 * */
const publicRoutes = [
  {
    path: "/support",
    component: SupportPage,
  },

  {
    path: "/signIn",
    component: SignIn,
  },
  {
    path: "/signUp",
    component: SignUp,
  },
  {
    path: "/otpVerification",
    component: OtpVerification,
  },
  {
    path: "/forgotPassword",
    component: ForgotPassword,
  },
  {
    path: "/createNewAccount",
    component: CreateNewAccount,
  },
  {
    path: "/resetPasswordPage",
    component: ResetPasswordNew,
  },
  //NOTE: This route should be at the end of all other routes.
  {
    path: "/",
    exact: true,
    component: SignIn,
  },
];

/**
 *  @type {Array}
 *  @description This Array contains all the Private Routes.
 * */
const authProtectedRoutes = [
  {
    path: "/onBoarding",
    component: OnboardingMainPage,
  },
  {
    path: "/connectorsPlayground",
    component: PipelinesPageNew,
  },
  {
    path: "/connectors",
    component: WorkSpaceConnectorsMainPage,
    isSideBar: false,
  },
  { path: "/oauth/authorize", component: OauthAuthenticatePage },
  { path: "/geoFenceCreation", component: GeoFenceMain },
  {
    path: "/buildLogs",
    component: BuildLogsAndConfiguration,
    isSideBar: false,
  },
  {
    path: "/connectorInformation",
    component: ConnectorInfoConfiguration,
  },
  {
    path: "/connectorConfiguration",
    exact: true,
    component: ConnectorsConfigurationPage,
  },

  { path: "/applications", component: ApplicationPage },
  { path: "/createApplication", component: NewApplicationPage },
  { path: "/codeEditor", component: VSCodeEditor },
  { path: "/repoInformation", component: RepoInformation },
  { path: "/settings", component: Settings },
];

export { authProtectedRoutes, publicRoutes };
