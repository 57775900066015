import React, { useCallback, useContext } from "react";
import PipelineConnectorCard from "../../Pipelines/Components/PipelineConnectorCard";
import PipelineBlankCard from "../../Pipelines/Components/PipelineBlankCard";
import { useHistory } from "react-router-dom";
import ReactFlow, { Background, ConnectionLineType } from "reactflow";
import { remToPx } from "../../../utils";
import styled from "styled-components";
import { SnackbarContext } from "../../../layouts/Context/snackBarContext";
import TopicEdge from "../../Pipelines/Components/TopicEdge";

const nodeTypes = {
  OUTPUT: PipelineConnectorCard,
  INPUT: PipelineConnectorCard,
  Input: PipelineConnectorCard,
  Output: PipelineConnectorCard,
  BLANK: PipelineBlankCard,
  "Custom Connector": PipelineConnectorCard,
};

const edgeTypes = {
  topic: TopicEdge,
};

const BackgroundStyled = styled(Background)`
  background: #0d0d0d;
`;

const PipelinesGraph = ({ nodes, edges, isNodeClickable }) => {
  const history = useHistory();
  const { showMessage } = useContext(SnackbarContext);
  const onNodeSelectedChange = useCallback(
    (_, node) => {
      if (node.type === "BLANK") {
        if (isNodeClickable) {
          history.push("/connectors");
        } else {
          showMessage("You don't have access to perform this operation");
        }
      } else if (node && node.data && node.data.configId && isNodeClickable) {
        history.push("/connectorInformation", {
          nodeData: node.data,
          nodes: nodes.filter((node) => node.type !== "BLANK"),
          edges,
        });
      } else if (node && node.data && node.data.configId && !isNodeClickable) {
        showMessage("You don't have access to perform this operation");
      }
    },
    [edges, history, isNodeClickable, nodes, showMessage],
  );

  return (
    <ReactFlow
      nodes={nodes}
      edges={edges}
      nodeTypes={nodeTypes}
      nodesConnectable={false}
      onNodeClick={onNodeSelectedChange}
      connectionLineType={ConnectionLineType.Bezier}
      edgeTypes={edgeTypes}
      fitView={nodes.length > 4}
    >
      <BackgroundStyled
        variant="dots"
        gap={remToPx(0.555)}
        size={remToPx(0.277)}
        color="#1C1C1C"
      />
    </ReactFlow>
  );
};

export default PipelinesGraph;
