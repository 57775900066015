import React, { useState, useContext, useEffect } from "react";
import { Col, Row } from "reactstrap";
import axiosAdapter from "../../utils";
import { env } from "../../env";
import styled from "styled-components";
import BackIcon from "../../assets/images/Back_Arrow.svg";
import ConnectorIcon from "../../assets/images/Connectors copy.svg";
import AdditionIcon from "../../assets/images/settings_icon.svg";
import ConnectorInfoForm from "./ConnectorInfoForm";
import ConnectorLogs from "./ConnectorInfoLog";
import EditConfigurationModal from "./EditConnectorInfoModal";
import GenericModal from "../common/_genericModal";
import Column from "../common/_customColumn";
import { useHistory, useLocation } from "react-router-dom";
import { SnackbarContext } from "../../layouts/Context/snackBarContext";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import {
  StyledButtonsecondary,
  StyledDeleteButton,
} from "../common/_buttonNewOne";

const Title = styled.div`
  font-family: "Articulat CF Medium";
  font-size: 1.67rem;
  text-align: left;
  color: #fafafa;
  padding-left: 1.112rem;
  font-weight: bold;
`;

const StyledRow = styled.div`
  display: flex;
  flex-wrap: nowrap;
  margin: 0;
  padding: 0;
`;

const Icon = styled.img`
  cursor: pointer;
`;

const ConnectorInfoTab = styled.div`
  display: flex;
  justify-content: center;
  cursor: pointer;
  gap: 0.55rem;
  color: ${(props) => (props.isActive ? "#47ccd6" : "#777777")};
`;
const TabIcon = styled.img`
  height: 1.67rem;
  width: 1.67rem;
  filter: ${(props) => (props.isActive ? "none" : "grayscale(100%)")};
`;

const TabName = styled.div`
  font-family: "Articulat CF Medium";
  font-size: 1.25rem;
  font-weight: bold;
`;

const TabLine = styled.div`
  position: relative;
  height: 0.277rem;
  width: 19.23rem;
  margin-left: 1.25rem;
  margin-top: 0.55rem;
  background-color: ${(props) => (props.isActive ? "#47ccd6" : "transparent")};
  z-index: 2;
  border-radius: 0.138rem;
`;

const Divider = styled.div`
  position: absolute;
  border: none;
  height: 0.138rem;
  background-color: #90909052;
  width: 100vw;
  z-index: 1;
  bottom: 0;
  left: 0;
`;

const ModalDescription = styled.div`
  font-family: "Articulat CF Medium";
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.55rem;
  color: #ececec;
  opacity: 0.7;
`;

export default function ConnectorInfoConfiguration(props) {
  const history = useHistory();
  const location = useLocation();
  const { showMessage } = useContext(SnackbarContext);
  const { nodeData, nodes, edges } = location.state || {};
  const [selectedTab, setSelectedTab] = useState("connectorInfo");
  const [editModal, setEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isLogsExpanded, setIsLogsExpanded] = useState(false);
  const [configs, setConfigs] = useState([]);
  const [allTopics, setAllTopics] = useState([]);

  useEffect(() => {
    (async () => {
      let fetchAllTopics = await axiosAdapter(
        "GET",
        env.REACT_APP_URL + "kafkaadmin/getAllTopics",
      );

      setAllTopics(fetchAllTopics.data.data.topics);
    })();
  }, []);

  // If there is no nodeData in the location state, go back to main page
  if (!nodeData) {
    return <Redirect to={{ pathname: "/" }} />;
  }

  // If there is no nodeData in the location state, go back to main page
  if (!nodeData) {
    return <Redirect to={{ pathname: "/" }} />;
  }

  const toggleEditModal = () => {
    setEditModal(!editModal);
  };
  const toggleDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal);
  };
  const handleTabChange = (tab) => {
    setSelectedTab(tab);
  };
  const handleDeleteConnector = async () => {
    const workspaceId = parseInt(
      localStorage.getItem("selectedWorkspaceId") || 0,
    );
    try {
      await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "pipeline/deleteNamespaceDeploymentNew",
        {
          connectorId: nodeData.configId,
          workspaceId: workspaceId,
          isDevice: nodeData.category === "Telematics Device",
        },
      );

      toggleDeleteModal();
      showMessage("Connector Deleted Successfully", "success");
      setTimeout(() => {
        history.push("/connectorsPlayground");
      }, 1000);
    } catch (err) {
      console.log("Error deleting connector:", err);
      if (err.response?.data?.remarks) {
        showMessage(err.response.data.remarks);
      } else {
        showMessage("Failed to delete connector");
      }
    }
  };

  return (
    <div style={{ backgroundColor: "#171717" }}>
      <StyledRow style={{ height: "8.194rem" }}>
        <Column xs={12}>
          <StyledRow style={{ padding: "1.112rem" }}>
            <Column xs={7} style={{ display: "flex", alignItems: "center" }}>
              <Icon
                src={BackIcon}
                alt="Back Icon"
                style={{ width: "1.4rem", height: "0.972rem" }}
                onClick={() => history.push("/connectorsPlayground")}
              />
              <Title>
                {nodeData.title ? nodeData.title : "Your Connector"}
              </Title>
            </Column>
            <Column
              xs={5}
              style={{
                display: "flex",
                gap: "1.112rem",
                justifyContent: "flex-end",
              }}
            >
              <StyledButtonsecondary
                onClick={toggleEditModal}
                style={{ width: "14.167rem" }}
                disabled={
                  nodeData.category === "Stream Connector" ||
                  nodeData.category === "Store Connector"
                }
              >
                Edit Configuration
              </StyledButtonsecondary>
              <StyledDeleteButton
                style={{ width: "14.167rem" }}
                onClick={toggleDeleteModal}
              >
                Delete Connector
              </StyledDeleteButton>
            </Column>
          </StyledRow>
          <StyledRow>
            <Column
              xs={2.5}
              sm={2.5}
              md={2.5}
              lg={2.5}
              xl={2.5}
              xxl={2.5}
              xxxl={2.5}
            >
              <ConnectorInfoTab
                onClick={() => handleTabChange("connectorInfo")}
                isActive={selectedTab === "connectorInfo"}
              >
                <TabIcon
                  src={ConnectorIcon}
                  alt="img"
                  isActive={selectedTab === "connectorInfo"}
                />
                <TabName>Connector Information</TabName>
              </ConnectorInfoTab>
              <div style={{ position: "relative" }}>
                <TabLine isActive={selectedTab === "connectorInfo"} />
                <Divider />
              </div>
            </Column>
            <Column
              xs={6}
              sm={6}
              md={6}
              lg={4}
              xl={2.3}
              style={{ minWidth: "19.236rem" }}
            >
              <ConnectorInfoTab
                // onClick={() => handleTabChange("additionalConfig")}
                style={{ cursor: "not-allowed" }}
                isActive={selectedTab === "additionalConfig"}
              >
                <TabIcon
                  src={AdditionIcon}
                  alt="img"
                  isActive={selectedTab === "additionalConfig"}
                />
                <TabName>Additional Configuration</TabName>
              </ConnectorInfoTab>
              <TabLine isActive={selectedTab === "additionalConfig"} />
            </Column>
          </StyledRow>
          <StyledRow>
            {/* <Column
              xs={12}
              style={{
                position: "absolute",
                border: "1px solid #90909052",
                height: "0",
                zIndex: 1,
                width: "100%",
                bottom: 820,
              }}
            ></Column> */}
          </StyledRow>
        </Column>
      </StyledRow>
      <StyledRow style={{ height: "calc(100vh - 11.66rem", overflow: "auto" }}>
        {selectedTab === "connectorInfo" && (
          <>
            <Column xs={5.2} style={{ padding: "1.112rem" }}>
              <ConnectorInfoForm nodeData={nodeData} setConfigs={setConfigs} />
            </Column>
            <Column
              xs={6.8}
              style={{ padding: "1.112rem 1.112rem 1.112rem 0px" }}
            >
              <ConnectorLogs
                isLogsExpanded={isLogsExpanded}
                setIsLogsExpanded={setIsLogsExpanded}
                nodeData={nodeData}
              />
            </Column>
          </>
        )}
        {selectedTab === "additionalConfig" && (
          <Title>Additional Configuration content will come up here</Title> // Additonal Configuration content will come up here
        )}
      </StyledRow>
      {editModal && (
        <EditConfigurationModal
          toggleEditModal={toggleEditModal}
          isModelOpen={editModal}
          configs={configs}
          setConfigs={setConfigs}
          allTopics={allTopics}
          setAllTopics={setAllTopics}
          nodeData={nodeData}
          edges={edges}
          nodes={nodes}
        />
      )}
      {showDeleteModal && (
        <GenericModal
          show={showDeleteModal}
          onClose={toggleDeleteModal}
          title={`Are you sure you want to delete the ${nodeData.title}?`}
        >
          <Row noGutters>
            <Col xs={12}>
              <ModalDescription>
                {`If you delete ${nodeData.title}, you might break the flow of data in the pipeline and might cause problems in your deployed applications.`}
              </ModalDescription>
            </Col>
          </Row>
          <Row
            noGutters
            style={{
              display: "flex",
              gap: "1.112rem",
              justifyContent: "flex-end",
              marginTop: "6.1805rem",
            }}
          >
            <StyledButtonsecondary
              onClick={toggleDeleteModal}
              style={{ width: "14.167rem" }}
            >
              Cancel
            </StyledButtonsecondary>
            <StyledDeleteButton
              style={{ width: "14.167rem" }}
              onClick={handleDeleteConnector}
            >
              Delete Connector
            </StyledDeleteButton>
          </Row>
        </GenericModal>
      )}
    </div>
  );
}
